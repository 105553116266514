<template>
  <b-card class="blog-edit-wrapper">
    <!-- form -->
    <div class="text-center">
      <h4 class="mt-5">
        Add Post / News

        <feather-icon icon="Edit2Icon" />
      </h4>

    </div>
    <validation-observer ref="addCourseFormvalidate">
      <b-form
        ref="form"
        class="mt-2"
        style="width: 100%"
        @submit.prevent="save"
      >
        <b-row>

          <b-col md="4">
            <b-form-group
              label="Title"
              label-for="blog-edit-title"
              class="mb-2"
            >
              <validation-provider
                #default="{ errors }"
                name="Title"
                rules="required"
              >
                <b-form-input
                  id="blog-edit-title"
                  v-model="addCourseForm.title"
                />
                <small class="text-danger">{{ errors[0] }}</small>
              </validation-provider>
            </b-form-group>
          </b-col>
          <b-col md="4">
            <b-form-group
              label="Show In"
              label-for="blog-edit-category"
              class="mb-2"
            >
              <validation-provider
                #default="{ errors }"
                name="Show In"
                rules=""
              >
                <v-select
                  id="blog-edit-category"
                  v-model="addCourseForm.type"
                  :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
                  label="label"
                  :clearable="false"
                  :options="showList"
                  :reduce="(val) => val.id"
                  @input="getNews"
                />
                <small class="text-danger">{{ errors[0] }}</small>
              </validation-provider>
            </b-form-group>
          </b-col>
          <b-col md="6">
            <b-form-group
              label="Sub Title"
              label-for="blog-edit-slug"
              class="mb-2"
            >

              <validation-provider
                #default="{ errors }"
                name="Sub Title"
                rules="required"
              >
                <b-form-input
                  id="blog-edit-slug"
                  v-model="addCourseForm.subtitle"
                />

              </validation-provider>
            </b-form-group>
          </b-col>
          <b-col md="6">
            <b-form-group
              label="In-Link Name"

              label-for="blog-edit-cgory"
              class="mb-2"
            >
              <validation-provider
                #default="{ errors }"
                name="YouTube Link"
                rules="required"
              >
                <b-form-input
                  id="videor_link"
                  v-model="addCourseForm.slug"
                  @input="removeSpace($event)"
                />
                <small class="text-danger">{{ errors[0] }}</small>
                <p v-if="addCourseForm.type==1">
                  link after creation:{{ `https://api.lmitac.com/articles/${addCourseForm.slug }` }}
                </p>
                <p v-else>
                  link after creation:{{ `https://api.lmitac.com/news/${addCourseForm.slug }` }}
                </p>
              </validation-provider>
            </b-form-group>
          </b-col>

          <b-col
            cols="12"
            class="mb-2"
          >
            <div class="border rounded p-2">
              <h4 class="mb-1">
                Tab image
              </h4>
              <b-media
                no-body
                vertical-align="center"
                class="flex-column flex-md-row"
              >
                <div class="d-inline-block">
                  <b-media-aside>
                    <b-img
                      ref="refPreviewE2"
                      :src="Tabimage"
                      height="110"
                      width="170"
                      class="rounded mr-2 mb-1 mb-md-0"
                    />
                  </b-media-aside>

                  <b-button
                    v-ripple.400="'rgba(186, 191, 199, 0.15)'"
                    variant="outline-secondary"
                    class="rounded ml-1 mt-2 text-center"
                    @click="refPreviewE2.src = Tabimage
                            addCourseForm.tab_image=''
                    "
                  >
                    Remove Image
                  </b-button>
                </div>
                <b-media-body>
                  <b-card-text class="mt-1" />
                  <div class="d-inline-block  mt-1">
                    <b-form-group
                      label="Tab Image "
                      label-for="blog-edit-title"
                      class="mb-2"
                    >
                      <validation-provider
                        #default="{ errors }"
                        name="Tab Image "
                        rules="required"
                      >
                        <b-form-file
                          ref="refInputE2"
                          v-model="addCourseForm.tab_image"
                          accept=".jpg, .png, .gif, .jpeg"
                          placeholder="Choose file"
                          @input="inputImageRendererTab"
                        />
                        <small class="text-danger">{{ errors[0] }}</small>
                      </validation-provider>
                    </b-form-group>
                    <b-form-group
                      label="Tab Image Alt Text"
                      label-for="blog-edit-title"
                      class="mb-2"
                    >
                      <b-form-input
                        id="alt-image"
                        v-model="addCourseForm.alt_tab_image"
                      />
                    </b-form-group>
                  </div>
                </b-media-body>
              </b-media>
            </div>
            <div class="border rounded p-2">
              <h4 class="mb-1">
                Header image
              </h4>
              <b-media
                no-body
                vertical-align="center"
                class="flex-column flex-md-row"
              >
                <div class="d-inline-block">
                  <b-media-aside>
                    <b-img
                      ref="refPreviewEl"
                      :src="Tabimage"
                      height="110"
                      width="170"
                      class="rounded mr-2 mb-1 mb-md-0"
                    />
                  </b-media-aside>
                  <b-button
                    v-ripple.400="'rgba(186, 191, 199, 0.15)'"
                    variant="outline-secondary"
                    class="rounded ml-1 mt-2 text-center"
                    @click="refPreviewEl.src = Tabimage
                            ddCourseForm.header_image=''
                    "
                  >
                    Remove Image
                  </b-button>
                </div>
                <b-media-body>
                  <b-card-text class="mt-1" />
                  <div class="d-inline-block  mt-1">
                    <b-form-group
                      label="Header Image "
                      label-for="blog-edit-title"
                      class="mb-2"
                    >
                      <validation-provider
                        #default="{ errors }"
                        name="Header Image "
                        rules="required"
                      >
                        <b-form-file
                          ref="refInputEl"
                          v-model="addCourseForm.header_image"
                          accept=".jpg, .png, .gif, .jpeg"
                          placeholder="Choose file"
                          @input="inputImageRenderer"
                        />
                        <small class="text-danger">{{ errors[0] }}</small>
                      </validation-provider>
                    </b-form-group>
                    <b-form-group
                      label="Header image Alt Text"
                      label-for="blog-edit-title"
                      class="mb-2"
                    >
                      <b-form-input
                        id=" alt_header_image"
                        v-model="addCourseForm.alt_header_image"
                      />
                    </b-form-group>
                  </div>
                </b-media-body>
              </b-media>
            </div>
          </b-col>
          <b-col
            v-if="addCourseForm.type==1"
            md="6"
          >
            <b-form-group
              label="Article Category"
              label-for="blog-edit-course"
              class="mb-2"
            >
              <validation-provider
                #default="{ errors }"
                name="Courses"
                rules=""
              >
                <v-select
                  id="blog-edit-category"
                  v-model="addCourseForm.category"
                  :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
                  label="type"
                  :options="categoriesList"
                  :reduce="(val) => val.id"
                  @input="getCoursesByCategory(addCourseForm.category)"
                />
                <small class="text-danger">{{ errors[0] }}</small>
              </validation-provider>
            </b-form-group>
          </b-col>

          <b-col
            v-if="addCourseForm.type==1"
            md="6"
          >
            <b-form-group
              label="Related Course"
              label-for="blog-edit-course"
              class="mb-2"
            >
              <validation-provider
                #default="{ errors }"
                name="Courses"
                rules=""
              >
                <v-select
                  id="blog-edit-category"
                  v-model="addCourseForm.related_courses"
                  :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
                  label="name"
                  :options="coursesList"
                  multiple
                  :reduce="(val) => val.id"
                >
                  <template
                    v-if="!getCourse"
                    #no-options="{ search, searching, loading }"
                  >
                    Select Category First ...
                  </template>
                  <template
                    v-else
                    #no-options="{ search, searching, loading }"
                  >
                    Loading ...
                  </template>
                </v-select>
                <small class="text-danger">{{ errors[0] }}</small>
              </validation-provider>
            </b-form-group>
          </b-col>
          <b-col md="12">
            <b-form-group
              label=" "
              label-for="blog-edit-course"
              class="mb-2"
            >

              <label v-if="addCourseForm.type==1"> Related Article</label>
              <label v-else> Related News</label>
              <validation-provider
                #default="{ errors }"
                name="Courses"
                rules=""
              >
                <v-select
                  id="blog-edit-category"
                  v-model="addCourseForm.related_post"
                  :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
                  label="title"
                  multiple
                  :options="articleByCategoryList"
                  :reduce="(val) => val.id"
                >
                  <template
                    v-if="!getCourse  && addCourseForm.type==1"
                    #no-options="{ search, searching, loading }"
                  >
                    Select Category First ...
                  </template>
                  <template
                    v-else
                    #no-options="{ search, searching, loading }"
                  >
                    Loading ...
                  </template>
                </v-select>
                <small class="text-danger">{{ errors[0] }}</small>
              </validation-provider>
            </b-form-group>
          </b-col>
          <b-col cols="12">
            <b-form-group
              label="Details"
              label-for="blog-content"
              class="mb-2"
            >
              <validation-provider
                #default="{ errors }"
                name="Details"
                rules="required"
              >
               <QuillEditor
                  :editorRef="'whatWeDo4Editor'"
                v-model="addCourseForm.details"
                />

               
                <small class="text-danger">{{ errors[0] }}</small>
              </validation-provider>
            </b-form-group>
          </b-col>
          <b-col
            cols="12"
            class="mt-50"
          >
            <b-button
              v-if="!isLoading"
              v-ripple.400="'rgba(255, 255, 255, 0.15)'"
              variant="primary"
              class="mr-1"
              @click="save"
            >
              Save Changes
            </b-button>

            <b-button
              v-if="isLoading"
              variant="primary"
              disabled
              class="mr-1"
            >
              Loading...
              <b-spinner small />
              <span class="sr-only" />
            </b-button>
          </b-col>
        </b-row>
      </b-form>
    </validation-observer>
    <!--/ form -->
  </b-card>
</template>

<script>
import {
  BCard,
  BMedia,
  BCardText,
  BMediaAside,
  BMediaBody,
  BForm,
  BRow,
  BCol,
  BFormGroup,
  BFormInput,
  BImg,
  BFormFile,
  BButton,
} from 'bootstrap-vue'
import { ValidationProvider, ValidationObserver } from 'vee-validate'
import vSelect from 'vue-select'
import { required, email } from '@validations'
import Ripple from 'vue-ripple-directive'
import { useInputImageRenderer } from '@core/comp-functions/forms/form-utils'
import { ref, reactive } from '@vue/composition-api'
import Vue from 'vue'
import Editor from '@tinymce/tinymce-vue'
import store from '@/store'
import QuillEditor from '@core/components/editor/Editor'
export default {
  setup() {
    // const store = useStore();
    const refInputEl = ref(null)
    const refInputE2 = ref(null)
    const refPreviewEl = ref(null)
    const refPreviewE2 = ref(null)
    const getCourse = ref(false)
    const coursesList = ref([])
    const articleByCategoryList = ref([])
    const form = ref()
    const removeSpace = target => {
      addCourseForm.slug = target // LowerCase
        .toLowerCase() // LowerCase
        .replace(/\s+/g, '-') // space to -
        .replace(/&/g, '') // & to and
        .replace(/--/g, '-')
        .replace(/[.,]/g, '-')
        .replace(/[_]/g, '')
        .replace(/[!''"":=]/g, '')
        .replace(/[!?%$^~*#()+]/g, '')
    }
    const addCourseForm = reactive({

      title: '',
      slug: '',
      tab_image: [],
      header_image: [],
      alt_tab_image: '',
      alt_header_image: '',
      type: 1,
      subtitle: '',
      related_courses: [],
      related_post: [],
      details: '',

      category_id: '',
    })
    const Tabimage = ref('media/svg/files/blank-image.svg')
    const categoriesList = ref([])
    store.dispatch('categories/CategoryList')
      .then(response => {
        categoriesList.value = response.data
      })

    const getCoursesByCategory = id => {
      const { type } = addCourseForm
      getCourse.value = true
      addCourseForm.course = ''
      addCourseForm.related_article = ''
      addCourseForm.related_post = []
      store
        .dispatch('categories/getCoursesByCategory', {
          ' category_id': id,
        })
        .then(response => {
          response.data.data.forEach(el => {
            if (el.online == 1) {
              coursesList.value.push({
                id: el.id,
                name: `${el.name} Online`,
                link_id: el.link_id,
              })
            } else {
              coursesList.value.push({
                id: el.id,
                name: el.name,
                link_id: el.link_id,
              })
            }
          })
        })
      store.dispatch('categories/getCategoryByArticle', { id, type })
        .then(response => {
          articleByCategoryList.value = response.data.data
        })
    }
    const isLoading = ref(false)

    const { inputImageRenderer } = useInputImageRenderer(
      refInputEl,
      base64 => {
        refPreviewEl.value.src = base64
      },
    )
    const showList = ref([{
      id: 2,
      label: 'News',
    },
    {
      id: 1,
      label: 'Articles',
    },

    ])
    const { inputImageRendererTab } = useInputImageRenderer(
      refInputE2,
      base64 => {
        refPreviewE2.value.src = base64
      },
    )
    const addCourseFormvalidate = ref()

    const save = () => {
      const formData = new FormData()

      addCourseFormvalidate.value.validate().then(success => {
        if (success) {
          formData.append('title', addCourseForm.title)
          formData.append('subtitle', addCourseForm.subtitle)
          formData.append('slug', addCourseForm.slug)
          formData.append('tab_image', addCourseForm.tab_image)
          formData.append('header_image', addCourseForm.header_image)
          formData.append('alt_tab_image', addCourseForm.alt_tab_image)
          formData.append(' alt_header_image', addCourseForm.alt_header_image)
          formData.append('type', addCourseForm.type)
          formData.append('subtitle', addCourseForm.subtitle)
          if(addCourseForm.category)
          {
            formData.append('category_id', addCourseForm.category)
          }
      
          formData.append('details', addCourseForm.details)
          formData.append(
            'related_courses',
            JSON.stringify(addCourseForm.related_courses),
          )
          formData.append(
            'related_posts',
            JSON.stringify(addCourseForm.related_post),
          )
          isLoading.value = true

          store.dispatch('blog/addBlog', formData)
            .then(response => {
              form.value.reset()
              isLoading.value = false
              addCourseForm.related_courses = []
              addCourseForm.category_id = ''
              addCourseForm.related_post = []
              refPreviewEl.value.src = 'media/svg/files/blank-image.svg'
              refPreviewE2.value.src = 'media/svg/files/blank-image.svg'

              form.value.reset()
              Vue.swal({
                title: ' Added ',
                icon: 'success',
                customClass: {
                  confirmButton: 'btn btn-primary',
                },
                buttonsStyling: false,
              })
            }).catch(error => {
              isLoading.value = false
              Vue.swal({
                title: '',
                text: `${error.response.data.message}`,
                icon: 'error',
                confirmButtonText: 'ok',
                customClass: {
                  confirmButton: 'btn btn-primary',
                },
                buttonsStyling: false,
              })
            })
        }
      })
    }
    function example_image_upload_handler(blobInfo, success, failure, progress) {
      let xhr; let
        formData
      const token = localStorage.getItem('token')

      xhr = new XMLHttpRequest()
      xhr.withCredentials = false
      xhr.open('POST', 'https://api.lmitac.com/api/upload_image_tiny_mce')

      xhr.upload.onprogress = function (e) {
        progress(e.loaded / e.total * 100)
      }
      xhr.setRequestHeader('Authorization', `Bearer ${token}`)
      xhr.onload = function () {
        let json

        if (xhr.status === 403) {
          failure(`HTTP Error: ${xhr.status}`, { remove: true })
          return
        }

        if (xhr.status < 200 || xhr.status >= 300) {
          failure(`HTTP Error: ${xhr.status}`)
          return
        }

        json = JSON.parse(xhr.responseText)

        if (!json || typeof json.location !== 'string') {
          failure(`Invalid JSON: ${xhr.responseText}`)
          return
        }

        success(json.location)
      }

      xhr.onerror = function () {
        failure(`Image upload failed due to a XHR Transport error. Code: ${xhr.status}`)
      }

      formData = new FormData()
      formData.append('image', blobInfo.blob(), blobInfo.filename())

      xhr.send(formData)
    }
    const getNews = () => {
      if (addCourseForm.type == 2) {
        addCourseForm.course = ''
        addCourseForm.category=null
        addCourseForm.related_article = ''
        addCourseForm.related_post = []
        store
          .dispatch('blog/getAllNews')
          .then(response => {
            articleByCategoryList.value = response.data.data
          })
      }
    }
    return {
      getNews,
      example_image_upload_handler,
      refInputEl,
      isLoading,
      refPreviewEl,
      refInputE2,
      refPreviewE2,
      inputImageRendererTab,
      getCourse,
      Tabimage,
      getCoursesByCategory,
      addCourseForm,
      inputImageRenderer,
      addCourseFormvalidate,
      articleByCategoryList,
      categoriesList,
      form,
      showList,
      save,
      removeSpace,
      required,
      email,
      coursesList,
    }
  },

  components: {
    BCard,
    BMedia,
    QuillEditor,
    ValidationProvider,
    ValidationObserver,
    BCardText,
    Editor,
    BMediaAside,
    BMediaBody,
    BForm,
    BImg,
    BRow,
    BCol,
    BButton,
    BFormGroup,
    BFormInput,
    BFormFile,
    vSelect,
  },

  directives: {
    Ripple,
  },
}
</script>

<style lang="scss">
@import "@core/scss/vue/libs/vue-select.scss";
@import "@core/scss/vue/libs/quill.scss";
@import "@core/scss/vue/pages/page-blog.scss";
</style>
